<template>
<div class="my-4">
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-3">
          <b-button v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'impactexpert' }}">add expert</b-button>
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card class="mb-3" v-for="row in showRows" :key="row.id">
          <expert
            :item="row"
            :username="user.username"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

import Expert from '@/components/Expert.vue'

export default {
  name: 'Experts',
  components: {
    Expert
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact experts', action: 'open impact experts' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('impactexpert').granted
    this.load()
  },
  data () {
    return {
      permissions: {
        add: false
      },
      filter: '',
      loading: true,
      rows: [],
      showRows: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/impactexpert'
        this.rows = await this.$Amplify.API.get(apiName, path)
        this.showRows = this.rows
        this.loading = false
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    },
    search: function () {
      if (this.filter.length > 2) {
        this.showRows = this.rows.filter(item => item.name.toUpperCase().includes(this.filter.toUpperCase()))
      } else {
        this.showRows = this.rows
      }
    }
  },
  watch: {
    'filter': 'search'
  }
}
</script>

<style>
</style>
